import React from 'react';
import Layout from "../components/layout"
import Pdf from '../assets/Anmeldeformular.pdf';
import Seo from "../components/seo";

const divParaStyle = {
  marginTop: '70px'
}

const AnmeldungUndKontakt = () => {
  return (
      <Layout>
        <Seo title="Anmeldung und Kontakt zur Naturspielgruppe Wakiti in Uster" />
        <div className='content'>
            <h1>Anmeldung</h1>
            <div style={divParaStyle}>
              <p>Für das Spielgruppenjahr 2023/2024 sind dienstagmorgens noch Plätze frei, <br/>gerne nehmen wir Ihre Anmeldung entgegen.</p>
              <p>Das ausgefüllte <a href={Pdf} target="_blank" rel='noopener noreferrer'>Anmeldeformular</a> senden Sie bitte an:</p>
              <p>Jill Zimmermann <br/>Reussinsel 44 <br/>6003 Luzern</p>
              <p>Email: <a id='email' href='mailto:jill.zimmermann@spielgruppe-wakiti.ch'>jill.zimmermann@spielgruppe-wakiti.ch</a></p>
              <p>Für weitere Informationen sind wir gerne telefonisch verfügbar.</p>
              <p>Telefon: <a href='tel:0041794724244'>079 472 42 44</a></p>
            </div>     
        </div>
    </Layout>
  )
}

export default AnmeldungUndKontakt;